import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";

export default function productUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'product_id', label: 'ID', sortable: true },
    // { key: 'company_creator', label: '创建组织', sortable: true },
    // { key: 'company_owner', label: '使用组织', sortable: true },
    { key: 'code', label: '物料编码', sortable: true },
    { key: 'name', label: '物料名称', sortable: true },
    { key: 'egg_erp_code', label: '金蝶名称', sortable: true },
    // { key: 'image', label: '图片', sortable: true },
    { key: 'specification', label: '规格型号', sortable: true },
    // { key: 'category_tow_id', label: '二级分组ID', sortable: true },
    // { key: 'category_three_id', label: '三级分组ID', sortable: true },
    // { key: 'product_brandlabel', label: '品牌标签 product_brandlabel', sortable: true },
    // { key: 'product_type', label: '存货类别 product_type', sortable: true },
    // { key: 'tax_type', label: '税分类 product_faxtype', sortable: true },
    // { key: 'cost_added_rate', label: '结算成本价加减价比例(%)', sortable: true },
    // { key: 'gross_weight', label: '毛重', sortable: true },
    // { key: 'net_weight', label: '净重', sortable: true },
    // { key: 'weight_unit', label: '重量单位 weight_unit', sortable: true },
    { key: 'product_unit', label: '单位', sortable: true },
    { key: 'box_quantity', label: '单份标准数量', sortable: true },
    { key: 'category_id', label: '分类', sortable: true },
    { key: 'brand_id', label: '品牌', sortable: true },
    { key: 'tax_rate', label: '税率', sortable: true },
    { key: 'size', label: '尺寸' },
    // { key: 'enable_stock_lock', label: '是否可以锁库', sortable: true },
    // { key: 'enable_stock_inventory', label: '是否周期盘点', sortable: true },
    // { key: 'inventory_period', label: '盘点周期  inventory_period', sortable: true },
    // { key: 'inventory_day', label: '盘点固定日期(第几天)', sortable: true },
    // { key: 'guarantee_period_unit', label: '保质期单位 product_unit_period', sortable: true },
    { key: 'guarantee_period', label: '保质期', sortable: true },
    // { key: 'best_shelf_life', label: '在架寿命期', sortable: true },
    // { key: 'cost_reference', label: '参考成本', sortable: true },
    // { key: 'min_qty', label: '最小库存', sortable: true },
    // { key: 'safe_qty', label: '安全库存', sortable: true },
    // { key: 'max_qty', label: '最大库存', sortable: true },
    // { key: 'repeat_qty', label: '再订货点', sortable: true },
    //{ key: 'product_attribute', label: '物料属性', sortable: true },
    { key: 'status', label: '物料状态', sortable: true },
    { key: 'image_id', label: '产品图片' },
    // { key: 'state', label: '启用', sortable: true },
    // { key: 'stockStr', label: '库存' },
    // { key: 'attachments', label: '', sortable: true },
     { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
     { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('product_id')
  const isSortDirDesc = ref(true)

  // 新增查询条件
  const product_code = ref('')
  const product_name = ref('')
  const product_specification = ref('')
  const product_status = ref('')
  const product_boxQuantity = ref('')
  const product_category_id = ref('')
  const product_brandId = ref('')
  const product_taxRate = ref('')
  const product_guaranteePeriodUnit = ref(undefined)
  const min_guarantee_period = ref('')
  const max_guarantee_period = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('product/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        product_code: product_code.value,
        product_name: product_name.value,
        product_specification: product_specification.value,
        product_status: product_status.value,
        product_boxQuantity: product_boxQuantity.value,
        product_category_id: product_category_id.value,
        product_brandId: product_brandId.value,
        product_taxRate: product_taxRate.value,
        product_guaranteePeriodUnit: product_guaranteePeriodUnit.value,
        min_guarantee_period: min_guarantee_period.value,
        max_guarantee_period: max_guarantee_period.value,
        listType:'finance',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        for (let i = 0; i < list.length; i++) {
          list[i]['stockStr'] = "查看"
        }
        callback(list)

      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '商品列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveProductTypeCode = value => {
    const val = String(value)
    if (val === '1') return {label: '渠道客户'}
    if (val === '2') return {label: '集团公司'}
    if (val === '3') return {label: '供应商'}
    if (val === '4') return {label: '仓库方'}
    if (val === '5') return {label: '物流方'}
    return {}
  }

  const resolveStateCode = value => {
    const val = String(value)
    if (val === '0') return {label: '禁用', color: 'secondary'}
    if (val === '1') return {label: '启用', color: 'success'}
    return {}
  }



  const resolveCompanyOwnerCode = value => {
    const val = String(value)
    if (val === '16') return {label: '禁用',}
    if (val === '17') return {label: '启用',}
    if (val === '18') return {label: '启用',}
    if (val === '19') return {label: '启用',}
    if (val === '20') return {label: '启用',}
    if (val === '21') return {label: '启用',}
    if (val === '36') return {label: '启用',}
    return {}
  }



  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    // 查询条件
    product_code,
    product_name,
    product_specification,
    product_status,
    product_boxQuantity,
    product_category_id,
    product_brandId,
    product_taxRate,
    product_guaranteePeriodUnit,
    min_guarantee_period,
    max_guarantee_period,

    resolveProductTypeCode,
    resolveStateCode,
    refetchData,

    // Extra Filters
  }
}
