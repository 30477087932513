<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!--<b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'apps-product-edit'}"
                class="hidden"
              >
                <span class="text-nowrap">添加</span>
              </b-button>-->

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料编码"
                    label-for="product_code"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_code"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料名称"
                    label-for="product_name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="规格型号"
                    label-for="product_specification"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_specification"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料状态"
                    label-for="allot_type"
                    label-size="sm"
                >
                  <v-select
                      id="allot_type"
                      :options="getCodeOptions('product_status')"
                      :clearable="false"
                      v-model="product_status_label"
                      class="select-size-sm"
                      @input="changeProductStatus($event)"
                      placeholder="选择物料状态"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="单份标准数量"
                    label-for="product_boxQuantity"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_boxQuantity"
                      class="d-inline-block mr-1"
                      size="sm"
                      type="number"
                  />
                </b-form-group>
              </b-col>


              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="分类"
                    label-for="category"
                    label-size="sm"
                >
                  <b-form-input
                      id="chooseId"
                      size="sm"
                      readonly
                      placeholder="请选择分类"
                      v-b-modal.modal-select-category
                      v-model="product_category_name"
                      class="d-inline-block mr-1"
                  />
                </b-form-group>
              </b-col>

              <b-modal
                  id="modal-select-category"
                  no-close-on-backdrop
                  ok-title="确认"
                  cancel-title="取消"
                  centered
                  @ok="onSelectCategory"
                  size="lg"
                  title="分类选择"
              >
                <category-select
                    :categoryOptions="category_options"
                    :level="level"
                    :showAll="true"
                    ref="categorySel">
                </category-select>

              </b-modal>

              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="品牌"
                    label-for="brand_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="brand_id"
                      :options="brandArray"
                      :clearable="false"
                      v-model="product_brandName"
                      @input="changeBrandId($event)"
                      placeholder="请选择品牌"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="默认税率"
                    label-for="tax_rate"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="tax_rate"
                      :options="getCodeOptions('tax_rate')"
                      :clearable="false"
                      v-model="product_taxRate_label"
                      class="select-size-sm"
                      @input="changeSelect('tax_rate', $event)"
                      placeholder="请选择默认税率"
                  />
                </b-form-group>
              </b-col>

<!--              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="保质期单位"
                    label-for="guarantee_period_unit"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="guarantee_period_unit"
                      :options="getCodeOptions('product_unit_period')"
                      :clearable="false"
                      v-model="product_guaranteePeriodUnitLabel"
                      @input="changeSelect('guarantee_period_unit',$event)"
                      class="select-size-sm"
                      placeholder="请选择保质期单位"
                  />
                </b-form-group>
              </b-col>-->

              <b-col cols="19" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="20"
                    label="保质期"
                    label-for="guarantee_period"
                    label-size="sm"
                    class="mb-1"
                    v-if="product_guaranteePeriodUnitLabel.length > 0 ? true: false"
                >
                  最小值:
                  <b-form-input
                      v-model="min_guarantee_period"
                      size="sm"
                      type="number"
                  />
                  最大值:
                  <b-form-input
                      v-model="max_guarantee_period"
                      size="sm"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(product_id)="data">
          #{{ data.item.product_id }}
        </template>


        <!-- Column: Type -->
        <!--        <template #cell(company_owner)="data">-->
        <!--          {{getCodeLabel('company_owner', data.item.company_owner)}}-->
        <!--        </template>-->

        <!--        <template #cell(company_owner)="data">-->

        <!--          {{getCodeLabel('company_owner', data.item.company_owner)}}-->
        <!--        </template>-->

        <!--        status-->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('product_status', data.item.status)}`"
          >
            {{ getCodeLabel('product_status', data.item.status) }}
          </b-badge>
        </template>

        <!--        product_attribute-->
        <template #cell(product_attribute)="data">
          {{ getCodeLabel('product_attribute', data.item.product_attribute) }}
        </template>

        <template #cell(image_id)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'image_id'"
                             :id="data.item.image_id"
                             :object_type="'product'"
                             :object_id="0"
                             :readonly="true"
                             @change="onUploaded"
          />
        </template>

        <!--        product_unit-->
        <template #cell(product_unit)="data">
          {{ getCodeLabel('product_unit', data.item.product_unit) }}
        </template>
        <template #cell(egg_erp_code)="data">
          <v-select
              :options="getCodeOptions('egg_erp_code')"
              :clearable="true"
              style="width: 300px"
              v-model="data.item.egg_erp_code"
              @input="changeEggErpCode($event,data.item)"
              class="select-size-sm"
              placeholder="选择金蝶名称"
              :reduce="option => option.value"
          />
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(stockStr)="data">
          <b-link
              @click="showStock(data.item)"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.stockStr }}
          </b-link>
        </template>

        <template #cell(category_id)="data">
          {{getCodeLabel('category', data.item.category_id)}} -> {{getCodeLabel('category', data.item.category_tow_id)}} -> {{ getCodeLabel('category', data.item.category_three_id) }}
        </template>

        <template #cell(brand_id)="data">
          {{ getCodeLabel('brand', data.item.brand_id) }}
        </template>

        <template #cell(tax_rate)="data">
          {{ getCodeLabel('tax_rate', data.item.tax_rate) }}
        </template>

        <template #cell(create_time)="data">
          {{toTime(data.item.create_time)}}
        </template>
        <template #cell(update_time)="data">
          {{toTime(data.item.update_time)}}
        </template>

        <template #cell(size)="data">
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
                              '<div class=text-left>'+
                              '宽度：'+data.item.width+'cm'+
                              '<br>'+'高度：'+data.item.height+'cm'+
                              '<br>'+'长度：'+data.item.length+'cm'+
                              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(guarantee_period)="data">
          {{data.item.guarantee_period}}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
                              '<div class=text-left>'+
                              '保质期单位：'+getCodeLabel('product_unit_period', data.item.guarantee_period_unit)+
                              '<br>'+'保质期：'+data.item.guarantee_period+
                              '<br>'+'在架寿命期：'+data.item.best_shelf_life+
                              '</div>'"
              variant="success"
          />
        </template>



        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <div v-if="data.item.status==3">
              <b-dropdown-item  @click="changeStatus(data.item,4)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">审核通过</span>
              </b-dropdown-item>

              <b-dropdown-item  @click="changeStatus(data.item,5)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">审核失败</span>
              </b-dropdown-item>

              <b-dropdown-item v-b-modal.modal-product-log @click="changeModifyLog(data.item)" >
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">修改日志</span>
              </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>

      </b-table>

      <b-modal
          id="modal-stock-log"
          ok-only
          centered
          size="xl"
          title="库存日志"
      >
        <stock-log-list :productid="id" :stockid="''"></stock-log-list>
      </b-modal>

      <b-modal
              id="modal-product-log"
              hide-footer
              centered
              size="xl"
              title="修改日志"
      >
        <modify-log-list :objectTypeProduct="1" :productid="id"  ></modify-log-list>
      </b-modal>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
          id="stockModal"
          ref="stockModal"
          ok-only
          ok-title="确认"
          @hide="hideModal"
          cancel-title="取消"
          centered
          size="xl"
          title="库存列表"
      >
        <stock-show-modal
            ref="stockShowModal" :productId="productId">
        </stock-show-modal>
      </b-modal>


      <!-- 复制行 模态框 -->
      <b-modal
          id="copyRowDataModal"
          ref="copyRowDataModal"
          centered
          size="sm"
          title="复制行"
          hide-footer
          @hide="hideCopyRowModal"
      >
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1 required"
        >
          <!--  v-model="product.code" -->
          <div>
            <b-form-input
                id="code"
                size="sm"
                key="productCode"
                @keyup.native="btKeyUp"
                v-model="productCode"
                onkeyup="value=value.replace(/[\W]/g,'') "
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品名称"
            label-for="productName"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="productName"
                size="sm"
                v-model="productName"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="specification"
                size="sm"
                v-model="specification"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="单份标准数量"
            label-for="box_quantity"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="boxQuantity"
                size="sm"
                type="number"
                v-model="boxQuantity"
                @mousewheel.prevent
            />
          </div>
        </b-form-group>
        <b-button
            size="sm"
            variant="outline-primary" @click="submitCopyRowData" style="float: right">确定
        </b-button>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, getCode,toTime, toDate, getCodeColor, getCodeLabel, getCodeOptions} from '@core/utils/filter'
import productUseList from './productUseList'
import productStore from '../productStore'
import StockShowModal from '@/views/apps/stock/stock-show-modal/StockShowModal'
import {getUserData} from '@/auth/utils'
import StockLogList from "@/views/apps/stocklog/StockLogList";
import Image from "@/views/components/image/Image";
import {useToast} from "vue-toastification/composition";
import Ripple from 'vue-ripple-directive'
import CategorySelect from '@/views/apps/category/category-modal/CategorySelect'
import brandStore from "@/views/apps/brand/brandStore";
import instance from "@/libs/axios";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import modifylogStore from "@/views/apps/modifylog/modifylogStore";
import ModifyLogList from "@/views/apps/modifylog/ModifyLogList";


export default {
  components: {
    //Image,
    AttachmentUpload,
    StockLogList,
    StockShowModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    CategorySelect,
    ModifyLogList
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: [],
      productId: 0,
      stockStr: "查看",
      selectedItem: {},
      userid: 0,
      id: 0,
      url: process.env.VUE_APP_OSS_URL,
      productCode: "",
      productName: "",
      specification: "",
      boxQuantity: "",
      judgeDisplay: undefined,
      rowData: [],
      advanced_search_modal: false,
      product_category_name: "",
      level: 3,
      category_options: [],
      brandArray: [],
      product_brandName: '',
      product_taxRate_label: '',
      product_guaranteePeriodUnitLabel: '',
      product_status_label: '',
      advanced_search_modal_excel: false,
      excelSqlId: '',
      companyNameExcel: '',
      productEdit: '',
      readonly: 1,
    }
  },
  methods: {
    btKeyUp(e) {
      e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, "");
    },
    changeState: function (data) {
      if (confirm("确定设置无效吗?")) {
        const state = 1 - data.state
        store.dispatch('product/state', {id: data.id, state: state}).then(res => {
          data.state = state
          this.refetchData()
        })
      }
    },
    showStock(item) {
      this.selectedItem = item
      this.productId = item.id
      this.$refs['stockModal'].show()
    },
    hideModal() {
      this.stockStr = this.$refs.stockShowModal.getStockStr();
      this.selectedItem['stockStr'] = this.stockStr;
    },
    changeModifyLog(data) {
      this.id=data.product_id
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('product')) store.registerModule('product', productStore)

    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)

    if (!store.hasModule('modifylog')) store.registerModule('modifylog', modifylogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('product')) store.unregisterModule('product')

      if (store.hasModule('brand')) store.unregisterModule('brand')

      if (store.hasModule('modifylog')) store.unregisterModule('modifylog')
    })

    const mystocklock = function (id) {
      this.$router.push({path: '/apps/stocklock/list?productid=' + id + '&creator=' + this.userid})
    }

    const changeId = function (val) {
      this.id = val.product_id
    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const changeStatus = function (params,status) {
      if (confirm("确定"+(status==4?"审核通过":"审核失败")+"吗?")) {
        store.dispatch('product/changeStatus', {productid: params.product_id,status:status}).then(res => {
          if (res.data.code === 0) {
            toast.success("操作成功!")
            const memo = status==4?"商品审核通过":"商品审核失败"
            store.dispatch('modifylog/save', {object_type:1,object_id: params.product_id,modify_content:memo}).then(res => {
            })
            refetchData()
          } else {
            toast.error(res.data.data)
            refetchData()
          }
        })
      }
    }

    const copyRow = function (params) {
      this.$refs['copyRowDataModal'].show()
      this.rowData = params
    }

    const submitCopyRowData = async function (param) {
      if (this.productCode === undefined || this.productCode === "") {
        toast.error('商品编码(69码)必填!')
        return false
      }

      if (this.productName === undefined || this.productName === "") {
        toast.error('商品名称必填!')
        return false
      }

      if (this.specification === undefined || this.specification === "") {
        toast.error('商品规格必填!')
        return false
      }

      if (this.boxQuantity === undefined || this.boxQuantity === "") {
        toast.error('单份标准数量必填!')
        return false
      }


      this.rowData.code = ',' + this.productCode + ','
      this.rowData.name = this.productName
      this.rowData.specification = this.specification
      this.rowData.box_quantity = this.boxQuantity.toString().substring(0, this.boxQuantity.toString().lastIndexOf("."))

      await store.dispatch('product/copyProduct', this.rowData).then(res => {
        if (res.data.code === 0) {
          toast.success('添加成功！')
          this.$refs['copyRowDataModal'].hide()
        } else {
          toast.error('添加失败！' + res.data.data)
        }
      })
      this.$refs['refListTable'].refresh()
    }

    const hideCopyRowModal = function () {
      this.productCode = ""
      this.productName = ""
      this.specification = ""
      this.boxQuantity = ""
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const changeEggErpCode = function(event,item){
      store.dispatch('product/changeEggErpCode', {id: item.id, egg_erp_code: event}).then(res => {
        toast.success("操作成功!")
        //this.refetchData()
      })
    }

    //yang
    const searchByConditionExcel = function () {

      const params = {
        excelSqlId: this.excelSqlId,
      }
      store.dispatch('product/export', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              toast.success("导入成功")
              refetchData();
            } else {
              toast.error(data)
            }
          })

    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }
    const onSelectCategory = function () { //赋值回显
      this.product_category_id = this.$refs.categorySel.categoryId + "," + this.$refs.categorySel.categoryTowId + "," + this.$refs.categorySel.categoryThreeId;
      this.category_options = [];
      this.category_options.push(
          {
            "label": this.$refs.categorySel.categoryName,
            "category_id": this.$refs.categorySel.categoryId,
          },
          {
            "label": this.$refs.categorySel.categoryTowName,
            "category_id": this.$refs.categorySel.categoryTowId,
          },
          {
            "label": this.$refs.categorySel.categoryThreeName,
            "category_id": this.$refs.categorySel.categoryThreeId,
          },
      );
      var new_content = this.$refs.categorySel.categoryName + "/";
      new_content += this.$refs.categorySel.categoryTowName + "/";
      new_content += this.$refs.categorySel.categoryThreeName + "/";
      this.product_category_name = new_content;
    }

    const changeBrandId = function (event) {
      if (event != null) {
        this.product_brandId = event.brand_id
      }
    }

    const getBrand = function (d) {
      store.dispatch('brand/search').then(res => {
        let a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          d.push({"label": a[i].name, "brand_id": a[i].brand_id})
        }
      })
    }

    const changeSelect = function (key, event) {
      if (event != null) {
        if (key === 'tax_rate') {
          this.product_taxRate_label = getCode("tax_rate", event.value).label
          this.product_taxRate = getCode("tax_rate", event.value).value
        } else if (key === 'guarantee_period_unit') {
          this.product_guaranteePeriodUnitLabel = getCode('product_unit_period', event.value).label
          this.product_guaranteePeriodUnit = getCode('product_unit_period', event.value).value
        }
      } else {
        if (key === 'tax_rate') {
          this.product_taxRate = ''
          this.product_taxRate_label = ''
        } else if (key === 'guarantee_period_unit') {
          this.product_guaranteePeriodUnitLabel = ''
          this.product_guaranteePeriodUnit = ''
        }
      }
    }

    const changeProductStatus = function (event) {
      if (event != null) {
        this.product_status_label = event.label
        this.product_status = event.value
      }
    }

    const searchByCondition = function () {
      let min = parseInt(this.min_guarantee_period)
      let max = parseInt(this.max_guarantee_period)
      if (max < min || min < 0) {
        this.$toast.error("保质期范围最小值/最大值错误")
      } else {
        this.refetchData()
      }
    }

    const resetCondition = function () {
      this.product_code = ''
      this.product_name = ''
      this.product_specification = ''
      this.product_status_label = ''
      this.product_status = ''
      this.product_boxQuantity = ''
      this.product_category_name = ''
      this.product_category_id = ''
      this.product_brandName = ''
      this.product_brandId = ''
      this.product_taxRate = ''
      this.product_taxRate_label = ''
      this.product_guaranteePeriodUnit = ''
      this.product_guaranteePeriodUnitLabel = ''
      this.min_guarantee_period = ''
      this.max_guarantee_period = ''

      this.refetchData()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // 查询条件
      product_code,
      product_name,
      product_specification,
      product_status,
      product_boxQuantity,
      product_category_id,
      product_brandId,
      product_taxRate,
      product_guaranteePeriodUnit,
      min_guarantee_period,
      max_guarantee_period
    } = productUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
      mystocklock,
      changeId,
      changeStatus,
      copyRow,
      submitCopyRowData,
      hideCopyRowModal,
      advanced_search,
      changeEggErpCode,
      onSelectCategory,
      changeBrandId,
      getBrand,
      changeSelect,
      searchByCondition,
      resetCondition,
      changeProductStatus,
      advanced_search_excel,
      searchByConditionExcel,
      onUploaded,
      toDate,
      toTime,

      // 查询条件
      product_code,
      product_name,
      product_specification,
      product_status,
      product_boxQuantity,
      product_category_id,
      product_brandId,
      product_taxRate,
      product_guaranteePeriodUnit,
      min_guarantee_period,
      max_guarantee_period
    }
  },

  created() {
    const userData = getUserData()
    this.userid = userData.user_id
    this.getBrand(this.brandArray)
    this.productEdit = getCodeLabel("setting", "product_edit")
    let array = this.productEdit.split(",")
    for (let i = 0; i < array.length; i++) {
      if (userData.role_id == array[i]) {
        this.readonly = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
